import type { ReactNode } from 'react'

import { KompProvider } from '@paniclobster/komp'

import ReactQueryProvider from '../ReactQueryProvider/ReactQueryProvider'

export interface ApplicationProviderProps {
  children: ReactNode
}

export default function ApplicationProvider(props: ApplicationProviderProps) {
  const { children } = props

  return (
    <KompProvider appElement="#__next">
      <ReactQueryProvider>{children}</ReactQueryProvider>
    </KompProvider>
  )
}
