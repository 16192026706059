/* eslint-disable react/jsx-filename-extension */

import Head from 'next/head'
import axios from 'axios'
import { Fragment } from 'react'
import { appWithTranslation } from 'next-i18next'

import type { AppProps } from 'next/app'

import ApplicationProvider from '../providers/ApplicationProvider/ApplicationProvider'

import '@paniclobster/komp/dist/theme.css'
import '@paniclobster/komp/dist/index.css'

import '../../styles/application.css'
import '../../styles/social.css'

axios.defaults.withCredentials = true

function App(props: AppProps) {
  const { Component, pageProps } = props

  return (
    <Fragment>
      <Head>
        <title>Panic Lobster</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <ApplicationProvider>
        <Component {...pageProps} />
      </ApplicationProvider>
    </Fragment>
  )
}

export default appWithTranslation(App) as (props: AppProps) => JSX.Element
