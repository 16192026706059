import type { ReactNode } from 'react'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { queryClient } from '../../helpers/query'

export interface ReactQueryProviderProps {
  children?: ReactNode
}

export default function ReactQueryProvider(props: ReactQueryProviderProps) {
  const { children } = props

  return (
    <QueryClientProvider client={queryClient}>
      {children}

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
