import { useMemo } from 'react'

import { QueryCache, QueryClient } from '@tanstack/react-query'

export const queryCache = new QueryCache()

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
    },
  },
})

export interface BaseQueryOptions {
  enabled?: boolean
  refetchInterval?: number | false
  refetchOnWindowFocus?: boolean
  staleTime?: number
  suspense?: boolean
  throwOnNotFound?: boolean
  throwOnUnauthorized?: boolean
  throwOnUncaught?: boolean
}

export function useQueryOptions(
  options: BaseQueryOptions,
  defaultOptions: Partial<BaseQueryOptions> = {},
) {
  const {
    enabled: defaultEnabled = true,
    refetchInterval: defaultRefetchInterval = false,
    refetchOnWindowFocus: defaultRefetchOnWindowFocus = true,
    staleTime: defaultStaleTime,
    suspense: defaultSuspense = false,
  } = defaultOptions

  const {
    enabled = defaultEnabled,
    refetchInterval = defaultRefetchInterval,
    refetchOnWindowFocus = defaultRefetchOnWindowFocus,
    staleTime = defaultStaleTime,
    suspense = defaultSuspense,
  } = options

  return useMemo(
    () => ({
      enabled,
      refetchInterval,
      refetchOnWindowFocus,
      staleTime,
      suspense,
    }),
    [enabled, refetchInterval, refetchOnWindowFocus, staleTime, suspense],
  )
}

export function useQueryCallbackOptions(
  options: BaseQueryOptions,
  defaultOptions: Partial<BaseQueryOptions> = {},
) {
  const {
    throwOnNotFound: defaultThrowOnNotFound = false,
    throwOnUnauthorized: defaultThrowOnUnauthorized = true,
    throwOnUncaught: defaultThrowOnUncaught = true,
  } = defaultOptions

  const {
    throwOnNotFound = defaultThrowOnNotFound,
    throwOnUnauthorized = defaultThrowOnUnauthorized,
    throwOnUncaught = defaultThrowOnUncaught,
  } = options

  return useMemo(
    () => ({ throwOnNotFound, throwOnUnauthorized, throwOnUncaught }),
    [throwOnNotFound, throwOnUnauthorized, throwOnUncaught],
  )
}
